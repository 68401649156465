import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ISubSpecialtyEntityState,
  ISubSpecialtyFeatureState,
  ISubSpecialtySharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './sub-specialty.adapter';
import { selectAll, selectEntities } from './sub-specialty.reducer';

export const getEntityState = (state: { entityState: ISubSpecialtyEntityState }) =>
  state.entityState;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectSubSpecialtyState =
  createFeatureSelector<ISubSpecialtyFeatureState & ISubSpecialtySharedState>('subSpecialties');

// Entity State
export const selectSubSpecialtyEntityState = createSelector(
  selectSubSpecialtyState,
  getEntityState
);

export const selectSubSpecialtyEntities = createSelector(
  selectSubSpecialtyEntityState,
  selectEntities
);
export const selectSubSpecialty = (id) =>
  createSelector(selectSubSpecialtyEntities, (entityDict) => entityDict[id]);

export const selectAllSubSpecialties = createSelector(selectSubSpecialtyEntityState, selectAll);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectSubSpecialtyState);
export const subSpecialtyPaginationSelectors = paginationAdapter.paginationSelectors(
  selectSubSpecialtyState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
