import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { paginationAdapter } from './profession.adapter';
export const { UpsertPageMessage, UpsertMultiplePagesMessage, ResetPaginationMessage } =
  paginationAdapter.getMessages();

export const ProfessionPaginationMessages = paginationAdapter.getMessages();

export class UpsertProfessionPageMessage extends UpsertPageMessage {}
export class UpsertProfessionMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetProfessionPaginationMessage extends ResetPaginationMessage {}

export enum ProfessionSignalTypes {
  LOAD_PROFESSIONS = '[Profession][Signal] Load Collection',
}
export enum ProfessionMessageTypes {
  SET_COLLECTION = '[Profession][Message] Set Collection',
  ADD_ONE = '[Profession][Message] Add One',
  UPDATE_ONE = '[Profession][Message] Update One',
  UPSERT_ONE = '[Profession][Message] Upsert One',
  DELETE_ONE = '[Profession][Message] Delete One',
  ADD_MULTIPLE = '[Profession][Message] Add All',
  DELETE_MULTIPLE = '[Profession][Message] Delete Many',
  UPSERT_MULTIPLE = '[Profession][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Profession][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfessionMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfessionEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfessionMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IProfessionEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfessionMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IProfessionEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfessionMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IProfessionEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfessionMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IProfessionEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfessionMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IProfessionEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfessionMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IProfessionEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfessionMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfessionMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ProfessionMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
