import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { distinctCollectionUntilChangedByKey } from '@locumsnest/core/src/lib/ngrx/operators';

import { PRODUCT_CODES } from '../../core/constants';
import { SPECIALTY_SERVICE_TOKEN } from '../../core/services/state/opaque-tokens';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { ISpecialtyService } from './../../specialty/+state/interfaces/index';
import {
  ResetSubSpecialtyPaginationMessage,
  SubSpecialtyPaginationMessages,
  UpsertMultipleMessage,
  UpsertSubSpecialtyPageMessage,
} from './sub-specialty.messages';
import { SubSpecialtyPersistenceService } from './sub-specialty.persistence.service';
import {
  selectAllSubSpecialties,
  selectSubSpecialty,
  selectSubSpecialtyEntityState,
  subSpecialtyPaginationSelectors,
} from './sub-specialty.selectors';

@Injectable({
  providedIn: 'root',
})
export class SubSpecialtyService extends FilterStateService<
  ISubSpecialtyEntity,
  UpsertSubSpecialtyPageMessage,
  ResetSubSpecialtyPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: SubSpecialtyPersistenceService,
    protected microAppService: MicroAppService,
    protected injector: Injector
  ) {
    super();
  }

  get paginationMessages() {
    return SubSpecialtyPaginationMessages;
  }

  get paginationSelectors() {
    return subSpecialtyPaginationSelectors;
  }

  get entityStateSelector() {
    return selectSubSpecialtyEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }
  get specialtyService(): ISpecialtyService {
    return this.injector.get(SPECIALTY_SERVICE_TOKEN);
  }

  getAllFiltersAfterLoading(): Observable<ISubSpecialtyEntity<number>[]> {
    return super
      .getAllFiltersAfterLoading()
      .pipe(map((filters) => filters.filter(({ published }) => published)));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectSubSpecialty(id)));
  }

  getAll() {
    return super.getAll().pipe(
      // filterNonEmpty,
      distinctCollectionUntilChangedByKey('id')
    );
  }

  getAllByCategory(categoryId: number) {
    return this.getAll().pipe(
      map(
        (subSpecialties) => subSpecialties.filter((specialty) => specialty.category === categoryId),
        distinctCollectionUntilChangedByKey('id')
      )
    );
  }

  getAllSubSpecialties() {
    return this.store.pipe(
      select(selectAllSubSpecialties),
      distinctCollectionUntilChangedByKey('id')
    );
  }

  addCategory(specialty: ISubSpecialtyEntity) {
    return this.specialtyService
      .getOne(specialty.category)
      .pipe(map((category) => ({ ...specialty, category })));
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
