import { InjectionToken } from '@angular/core';

import { IProfessionSpecialtyService } from '../../../profession/+state/interfaces/interfaces';
import { ISpecialtyService } from '../../../specialty/+state/interfaces';
import {
  IApplicationService,
  IStaffBankMembershipRequestService,
  IStaffingCascadeService,
} from '../interfaces';

export const APPLICATION_SERVICE_TOKEN = new InjectionToken<IApplicationService>(
  'APPLICATION_SERVICE_TOKEN',
);

export const STAFFING_CASCADE_SERVICE_TOKEN = new InjectionToken<IStaffingCascadeService>(
  'STAFFING_CASCADE_SERVICE_TOKEN',
);

export const STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN =
  new InjectionToken<IStaffBankMembershipRequestService>(
    'STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN',
  );

export const PROFESSION_SPECIALTY_SERVICE_TOKEN = new InjectionToken<IProfessionSpecialtyService>(
  'PROFESSION_SPECIALTY_SERVICE_TOKEN',
);

export const SPECIALTY_SERVICE_TOKEN = new InjectionToken<ISpecialtyService>(
  'SPECIALTY_SERVICE_TOKEN',
);
