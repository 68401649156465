import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createAlertStateAdapter, createPaginatedStateAdapter } from '@locumsnest/core/src';

export const adapter: EntityAdapter<ISubSpecialtyEntity> = createEntityAdapter<ISubSpecialtyEntity>(
  {}
);

export const messageableFactory = MessageableFactory.forFeature<'SubSpecialty'>('SubSpecialty');
export const signalableFactory = SignalableFactory.forFeature<'SubSpecialty'>('SubSpecialty');

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
export const loadingAdapter = createLoadingStateAdapter<'SubSpecialty'>(messageableFactory);
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedSubSpecialtyId: null,
});
