import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { ISubSpecialtyEntityState } from './interfaces';
import { paginationAdapter } from './sub-specialty.adapter';
export const SubSpecialtyPaginationMessages = paginationAdapter.getMessages();

export class UpsertSubSpecialtyPageMessage extends SubSpecialtyPaginationMessages.UpsertPageMessage {}
export class UpsertSubSpecialtyMultiplePagesMessage extends SubSpecialtyPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetSubSpecialtyPaginationMessage extends SubSpecialtyPaginationMessages.ResetPaginationMessage {}

export enum SubSpecialtySignalTypes {
  LOAD_SUB_SPECIALTIES = '[SubSpecialty][Signal] Load Collection',
}
export enum SubSpecialtyMessageTypes {
  SET_COLLECTION = '[SubSpecialty][Message] Set Collection',
  ADD_ONE = '[SubSpecialty][Message] Add One',
  UPDATE_ONE = '[SubSpecialty][Message] Update One',
  UPSERT_ONE = '[SubSpecialty][Message] Upsert One',
  DELETE_ONE = '[SubSpecialty][Message] Delete One',
  ADD_MULTIPLE = '[SubSpecialty][Message] Add All',
  DELETE_MULTIPLE = '[SubSpecialty][Message] Delete Many',
  UPSERT_MULTIPLE = '[SubSpecialty][Message] Upsert Many',
  UPDATE_MULTIPLE = '[SubSpecialty][Message] Update Many',
  UPDATE_ENTITY_STATE = '[SubSpecialty][Message] Update Entity State',
}
export class SetCollectionMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ISubSpecialtyEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ISubSpecialtyEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ISubSpecialtyEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ISubSpecialtyEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ISubSpecialtyEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ISubSpecialtyEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ISubSpecialtyEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = SubSpecialtyMessageTypes.UPDATE_ENTITY_STATE;

  constructor(public payload: { subSpecialtyTempState: ISubSpecialtyEntityState }) {}
}

export type SubSpecialtyMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage;
