import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfessionEntity } from './../../interfaces/api/profession-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfessionPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfessionEntity
> {
  protected readonly endpoint = 'profession';
}
