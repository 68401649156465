import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';

@Injectable({
  providedIn: 'root',
})
export class SubSpecialtyPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ISubSpecialtyEntity
> {
  protected readonly endpoint = 'specialty';
}
