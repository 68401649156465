import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IProfessionEntity> = createEntityAdapter<IProfessionEntity>({});
export const messageableFactory = MessageableFactory.forFeature<'Profession'>('Profession');
export const signalableFactory = SignalableFactory.forFeature<'Profession'>('Profession');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Profession'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedProfessionId: null,
});
